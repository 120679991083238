import React, { useState, useEffect, ChangeEvent } from "react";
import axios from 'axios';
import TextBoxInput from '../../components/TextBoxInput';
import ButtonEx from "../../components/ButtonEx";
import { Button, TextField } from "@mui/material";
import { Navigate, useNavigate } from "react-router-dom";


export const Login = () =>{

    const [text, setText] = useState('');

    // const [inputValue, setInputValue] = useState<string>('');

    // const handleInputChange = (value: string) => {
    //     setInputValue(value);
    // };

      // 入力フィールドの値が変更されたときのハンドラ
    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setText(event.target.value);
    };

    const navigate = useNavigate();

    useEffect(() => {

        if(localStorage.hasOwnProperty("staffname")) {
            const strWork : string = localStorage.getItem("staffname") as string;
            if(strWork!==""){
                navigate('/staff/cleanlist');
                return;
            }
        }

    }, []);

    function LoginCheck(){
        if (text.trim() !== "") {
            localStorage.setItem("staffname", text);
            
            navigate('/staff/cleanlist');
            return;
        }
        else
        {
            alert("スタッフ名を入力してください。");
        }
    }

    return (
        <>
        <div className="flex flex-col items-center justify-center b-login-wp">
            <div className='flex flex-col items-center justify-center max-w-80 b-login-wp-inner'>
                <img src="../../images/almondlogo.png" className='w-10 mb-2' />
                <h1 className="mb-10 tracking-wide">清掃記録管理システム</h1>
                <div className='flex flex-col items-start'>
                    <div className="mb-6">
                    <TextField
                        required
                        id="outlined-required"
                        size="small"
                        label="スタッフ名"
                        defaultValue=""
                        value={text}
                        onChange={handleInputChange}
                        inputProps={{
                            maxLength: 20,
                        }}
                        InputLabelProps={{ shrink: true }}
                        />
                    </div>
                    
                    <Button variant="contained" className="w-full" onClick={LoginCheck}>ログイン</Button>
                    
                </div>
            </div>
            
        </div>
        </>  
    )
}
export default Login;