import React, { useState, useEffect,FC, ReactNode } from "react";
import axios from 'axios';
import { Link, Navigate, useNavigate } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";
import { Drawer } from "@mui/material";
import { IoClose } from "react-icons/io5";
import CircularProgress from '@mui/material/CircularProgress';

// type Props = {
//     children: ReactNode;
//   };

  interface LayoutProps {
    children: ReactNode;
    isLoading : boolean;
    loadingText : string;
}

export const Wrapper: FC<LayoutProps> = ({children, isLoading, loadingText}) =>{

    const [text, setText] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    
    const [inputValue, setInputValue] = useState<string>('');

     

    const handleInputChange = (value: string) => {
        setInputValue(value);
    };

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    }

    const [isSmall, setIsSmall] = useState(false);

    const staffname : string = localStorage.getItem("staffname") as string;

    const navigate = useNavigate();

    //ログアウト
    const handleLogoutClick = () => {
        localStorage.setItem("staffname", "");
        navigate('/staff/login');
        return;
    }

    const handleScroll = () => {
        const scrollTop = window.scrollY;
        if (scrollTop > 300) {
            setIsSmall(true);
        } else {
            setIsSmall(false)
        }
    };
    
    useEffect(() => {

        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener("scroll", handleScroll);
        };

    }, []);

    return (
        <>
        <div className="flex flex-col justify-between items-center w-full b-wrapper">
            {isLoading ? (
                <div className="b-loadingcover">
                    <p><CircularProgress/></p>
                    <p className="text-blue-700 text-14px">{loadingText}</p>
                </div>
            ) : ''}            
            <header className={`w-full flex justify-between items-center b-header-wp  ${ isSmall ? 'small' : '' }`}>
                <div className="flex items-center gap-x-1">
                    <img src="../../images/almondlogo.png" className='w-6' />
                    <p className="text-14px text-medium tracking-wide">清掃記録システム</p>
                </div>
                <button onClick={toggleMenu} className="flex flex-col justify-between mainmenu-icn">
                <span></span>
                <span></span>
                <span></span>
                </button>
            </header>
            <main className="b-main-wp">
                {children}
            </main>
            <footer className="b-footer-wp">
                <p className="footer">©Almond Hostel & Cafe</p>
            </footer>

            <Drawer
            anchor={"right"}
            open={isOpen}
            onClose={toggleMenu}
            PaperProps={{
                sx: { width: "70%" },
              }}
            >
            <div className={`b-mainmenu-mob`}>
                <div className="flex justify-end pt-4 pb-1">
                    <button onClick={toggleMenu}><IoClose size={35} color={"#fff"} /></button>
                </div>
                <div className="b-mainmenu-mob-info">
                    <FaUserCircle size={20} />
                    <p>{staffname} さん</p>
                </div>
                <p className="text-left pt-3 pb-3 text-10px  ">MENU</p>
                <ul className="b-mainmenu-mob-list">
                    <li className="b-mainmenu-mob-item">
                        <Link to="../staff/cleanlist" className="b-mainmenu-mob-item-link"><span>清掃リスト</span></Link>
                    </li>
                    <li className="b-mainmenu-mob-item">
                        <button className="b-mainmenu-mob-item-link" onClick={()=>handleLogoutClick()}>ログアウト</button>
                    </li>
                </ul>
            </div>
            </Drawer>
            
        </div>  
        </>
        
    )
}
export default Wrapper;