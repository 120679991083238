import React, { useState, useEffect, useContext } from 'react';
import logo from './logo.svg';
import './App.css';
import './css/Shared.css';
import {BrowserRouter, Route, Routes, Navigate} from "react-router-dom";
import ReactDOM from 'react-dom'; 
import axios from 'axios';

import StaffLogin from './pages/staff/Login';
import StaffCleanArea from './pages/staff/CleanArea';
import StaffCleanList from './pages/staff/CleanList';

import MntLogin from './pages/mnt/MntLogin';
import MntCleanList from './pages/mnt/MntCleanList';
import MntCleanLatest from './pages/mnt/MntCleanLatest';

import AppContext, { useAppContext} from './components/AppContext';

function App() {

  const { mntAuthenticated, setMntAuthenticated } = useAppContext();

  useEffect(() => {

    if(localStorage.hasOwnProperty("isLogin")) {
      const isLogin : string = localStorage.getItem("isLogin") as string;
      if(isLogin!==""){
        setMntAuthenticated(true);
      }
    }
    
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
          <Routes>
            <Route path="/staff/login" element={<StaffLogin />} /> 
            <Route path="/staff/cleanlist" element={<StaffCleanList />} /> 
            <Route path="/staff/cleanarea" element={<StaffCleanArea />} /> 
            <Route path="/mnt/login" element={<MntLogin NextPage='cleanlatest' />} />
            <Route path="/mnt/cleanlist" element={mntAuthenticated ? <MntCleanList /> : <MntLogin NextPage='cleanlist' />} />
            <Route path="/mnt/cleanlatest" element={mntAuthenticated ? <MntCleanLatest /> : <MntLogin NextPage='cleanlatest' />} />
            <Route path="*" element={<h1>Not Found Page</h1>} />
          </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
