
import React, { useState, useEffect, FC } from "react";
import api from "../../configs/axios"
import { AppContext, useAppContext } from "../../components/AppContext";
import { Navigate, useNavigate } from "react-router-dom";

interface Props {
    NextPage : string;
}

export const MntLogin: FC<Props> = ({NextPage}) =>{

    const [password, setPassword] = useState<string>('');
    const [error, setError] = useState<string>('');
    const {MntLogin} = useAppContext();
    
    const navigate = useNavigate();

    useEffect(() => {

        if(localStorage.hasOwnProperty("isLogin")) {
            const isLogin : string = localStorage.getItem("isLogin") as string;
            if(isLogin!==""){
                //ログイン成功
                if(NextPage !== ""){
                    navigate('/mnt/' + NextPage);
                }
                else
                {
                    navigate('/mnt/cleanlatest');
                }           
                return;
            }
        }

    }, []);

    const handleLoginOnClick = async() => {
        try {


            // const response = await api.get('test_3.php', { withCredentials: true });
            // console.log(response);

            const formData = new FormData();
            formData.append("password", password);

            const response = await api.post('mnt_login_auth.php?type=login-check', formData);


            localStorage.setItem("isLogin", "ok");

            //console.log(response);
            MntLogin();

            //ログイン成功
            navigate('/mnt/cleanlatest');
            return;

        } catch (err) {
            setError('パスワードに誤りがあります。');
        }
    }

    return (
        <>
       
        <div className="flex flex-col items-center justify-center h-screen pb-10 pt-5">

            <div className="flex justify-center items-center mb-6 ml-10px-m">
                <img src="../../images/almondlogo.png" className='w-12' />
                <div className="text-left">
                <h1 className="text-20px font-light">清掃管理システム</h1>
                <p className="text-11px text-gray-800">ver.1.0.0</p>
                </div>                
            </div>

            <div className="b-mnt-login-wrap">
                
                <p className="text-16px font-medium mb-7 leading-none">管理画面ログイン</p>

                <div className="flex justify-center mb-5">
                    <div>
                    <p className="text-10px mb-1 text-left">パスワード</p>
                    <p><input type="password" name="" id="" className="input-text" style={{width:180}} value={password} onChange={(e) => {setPassword(e.target.value)}} /></p>
                    </div>
                    
                </div>

                

                <button className="btn-mnt-login" onClick={() => {handleLoginOnClick()}}>ログイン</button>
         

                {error !== "" ? (<p className="text-red-700 text-12px mt-5">{error}</p>) : ''}
            </div>
        </div>
        </>
    );
};
export default MntLogin;