import { createContext, useState, useEffect, useContext } from "react";
import api from '../configs/axios';

type Props = {
  children: JSX.Element;
};

type SystemData = {
  clean_date: string;
};

type AppContextType = {
  sysInfo: SystemData | null;
  setSysInfo: (sysInfo: SystemData) => void;
  mntAuthenticated: boolean;
  setMntAuthenticated : (mntAuthenticated: boolean) => void;
  MntLogout: () => void;
  MntLogin: () => void;
};

//contextを作成
export const AppContext = createContext<AppContextType | undefined>(undefined);

// Providerをコンポーネント化する
export default function Context({ children }: Props) {

    //Stateの設定 初期値を設定
    const [sysInfo, setSysInfo] = useState<SystemData | null>(null);

    const [mntAuthenticated, setMntAuthenticated] = useState<boolean>(false);

    useEffect(() => {

      if(localStorage.hasOwnProperty("isLogin")) {
        const isLogin : string = localStorage.getItem("isLogin") as string;
        if(isLogin!==""){
          setMntAuthenticated(true);
        }
      }

      //今日の日付を取得
      let d = new Date();
      let year = d.getFullYear();
      let month = d.getMonth() + 1;
      let day = d.getDate();

      setSysInfo({...sysInfo, clean_date:year+"-"+month+"-"+day})
      
        // const checkAuth = async () => {
        //     const response = await api.get('mnt_login_auth.php',
        //       {
        //         params : {
        //           type : "get-islogin"
        //         }
        //       }
        //     );
        //     setMntAuthenticated(response.data.authenticated);
        // };
        //checkAuth();
    }, []);

    const MntLogin = () => {
      
      setMntAuthenticated(true);
    };

    const MntLogout = async () => {
        await api.post('mnt_login_auth.php',
          {
            params : {
              type : "logout"
            }
          }
        );
        localStorage.setItem("isLogin", "");
        setMntAuthenticated(false);
    };


    // useEffect(() => {
      
    //      //今日の日付を取得
    //      let d = new Date();
    //      let year = d.getFullYear();
    //      let month = d.getMonth() + 1;
    //      let day = d.getDate();
 
    //      setSysInfo({...sysInfo, clean_date:year+"-"+month+"-"+day})

    // },[]);

    // valueを設定してProviderコンポーネントを返す
    return (
        <AppContext.Provider value={{ sysInfo, setSysInfo, mntAuthenticated, setMntAuthenticated, MntLogout, MntLogin }}>
            {children}
        </AppContext.Provider>
    );

    

}

// カスタムフックを作成してコンテキストを簡単に使えるようにする
export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
      throw new Error('useApp must be used within an AppContext');
  }
  return context;
};