import React, { useState, useEffect,FC, ReactNode } from "react";
import { Navigate, useNavigate } from "react-router-dom";

interface LayoutProps {
    children: ReactNode;
}

export const StaffAuth: React.FC<LayoutProps>  = ({children}) =>{

    const [isDisplay, setIsDisplay] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        //担当者名はあるか
        if(!localStorage.hasOwnProperty("staffname")) {
            navigate('/staff/login');
            return; 
        }
        const staffname : string = localStorage.getItem("staffname") as string;
        if (staffname === ""){
            navigate('/staff/login');
            return;
        }        
        setIsDisplay(true);
    }, []);

    if (!isDisplay){
        return (
            <></>
        );
    }
    
    return (
        <>
            {children}
        </>
    );
}
export default StaffAuth;