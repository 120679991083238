import React, { useState, useEffect } from "react";
import MntWrapper from "./MntWrapper";
import api from '../../configs/axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TextField } from "@mui/material";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {Box,  Pagination, Dialog, DialogContent} from "@mui/material";


type CleanAreaListType = {
    code : string,
    name : string,
}

type CleanDataType = {
    status : string,
    data : CleanDataListType[],
    total : string,
}
type CleanDataListType = {
    date: string,
    code: string, 
    name: string,
    fin_time: string,
    tanto: string,
    biko: string,
    pics: string[],
    tmbs: string[],
    page: string,
    limit: string,
}

export const MntCleanList = () =>{

    const [isloading, setLoading] = useState<boolean>(false);
    const [loadingText, setLoadingText] = useState<string>("");
    const [cleanDataList, setCleanDataList] = useState<CleanDataType | null>(null);
    const [cleanAreaList, setCleanAreaList] = useState<CleanAreaListType[] | null>(null);

    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dialogSelectedImage, setDialogSelectedImage] = useState<string | null>(null);

    const [jyokenAreas, setJyokenAreas] = useState<{ [key: string]: boolean }>({});
    const [jyokenCleanDateSt, setJyokenCleanDateSt] = useState<string>('');
    const [jyokenCleanDateEn, setJyokenCleanDateEn] = useState<string>('');
    const [jyokenTanto, setJyokenTanto] = useState<string>('');

    const [triggerPageDisplayEffect, setTriggerPageDisplayEffect] = useState(false);

    const uniqueTimestamp: string = Date.now().toString();

    useEffect(() => {

        setLoading(true);

        const fetchData = async () => {

            const res = await api.get('data_api.php', {
                params: {
                    type: "get-mnt-cleanplaceslist",
                }
            });
            setCleanAreaList(res.data as CleanAreaListType[]);

            const d : CleanAreaListType[] = res.data;

            d.map((item, idx) => {
                setJyokenAreas(prevData => ({
                    ...prevData,
                    [item.code]: true,
                }));
            })        

        }

        fetchData();

        setLoading(false);
        
    }, []);

    useEffect(() => {

        setLoading(true);

        const fetchData = async () => {
            try {

                const formData = new FormData();
                formData.append("page", page.toString());
                formData.append("limit", rowsPerPage.toString());
                formData.append("st_date", jyokenCleanDateSt);
                formData.append("en_date", jyokenCleanDateEn);
                formData.append("tanto", jyokenTanto);
                for (const key in jyokenAreas) {
                    if(jyokenAreas[key]){
                        formData.append("areas[]", key.toString());
                    }
                }
    

                const res = await api.post('data_api.php?type=get-mnt-cleanareadatalist', formData, {
                    
                });
                setCleanDataList(res.data as CleanDataType);

                console.log(res.data);

                setTotalPages(Math.ceil(Number(res.data.total) / rowsPerPage));

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();

        setTriggerPageDisplayEffect(false);

        setLoading(false);
        
    }, [page, rowsPerPage, triggerPageDisplayEffect]);

    const handleClickOpen = (imageSrc: string) => {
        setDialogSelectedImage(imageSrc);
        setIsDialogOpen(true);
    };

    const handleClose = () => {
        setIsDialogOpen(false);
        setDialogSelectedImage(null);
    };

    const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };


    // チェックボックスが変更されたときに呼び出される関数
    const handleJyokenCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, code : string) => {
        const { name, checked } = event.target;
        setJyokenAreas(prevState => ({
        ...prevState,
        [code]: checked,
        }));
    };
    
    const handleSearchSubmit = () => {
        // console.log(jyokenAreas);
        // console.log(jyokenCleanDateSt);
        // console.log(jyokenCleanDateEn);
        // console.log(jyokenTanto);
        setTriggerPageDisplayEffect(true);
    };

    const handleSearchClear = () => {
        for (const key in jyokenAreas) {
            setJyokenAreas(prevData => ({
                ...prevData,
                [key]: true,
            }));
        }
        setJyokenCleanDateSt('');
        setJyokenCleanDateEn('');
        setJyokenTanto('');
    };


    return (
        <>
        <MntWrapper isLoading={isloading} loadingText={loadingText}>
            <div>
                <h1 className="mnt-pagetitle">清掃履歴一覧</h1>

            <div className="b-mnt-search mb-5">
                <p className="mnt-search-title">検索条件</p>
                <div className="flex items-center">
                    <p className="text-left text-13px" style={{minWidth:'80px'}}>清掃日</p>
                    <div className="flex gap-2">
                    <input type="date" className="input-text text-12px" value={jyokenCleanDateSt} onChange={(e) => setJyokenCleanDateSt(e.target.value)} style={{width:'100px'}} />
                    <span>～</span>
                    <input type="date" className="input-text text-12px" value={jyokenCleanDateEn} onChange={(e) => setJyokenCleanDateEn(e.target.value)} style={{width:'100px'}} />
                    </div>
                </div>
                <div className="flex items-center">
                    <p className="text-left text-13px" style={{minWidth:'80px'}}>担当者</p>
                    <div className="flex gap-2">
                    <input type="text" className="input-text text-12px" value={jyokenTanto} onChange={(e) => setJyokenTanto(e.target.value)} style={{width:'100px'}} />
                    </div>
                </div>
                <div className="flex items-center justify-start mb-2">
                    <p className="text-left text-13px" style={{minWidth:'80px'}}>清掃場所</p>
                    <div className="flex flex-wrap gap-x-2">
                    {cleanAreaList?.map((item : CleanAreaListType, index) => (
                        <FormControlLabel key={index} control={<Checkbox size="small" style={{padding:'5px 8px'}} checked={jyokenAreas[item.code]} onChange={(e) => handleJyokenCheckboxChange(e, item.code)} sx={{ '& .MuiSvgIcon-root': { fontSize: 14, padding: 0 } }} value={item.code} />} label={item.name} sx={{ '& .MuiFormControlLabel-label': { fontSize: '13px' } }} />
                    ))}
                    
                    </div>
                </div>
                <div className="flex justify-start gap-x-4">
                    <button className="btn-mnt-01" onClick={()=>handleSearchSubmit()}>検索</button>
                    <button className="btn-mnt-02" onClick={()=>handleSearchClear()}>条件クリア</button>
                </div>
            </div>

                <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center" style={{width:'150px'}}>清掃日時</TableCell>
            <TableCell align="center" style={{width:'180px'}}>清掃場所</TableCell>
            <TableCell align="center" style={{width:'150px'}}>担当者</TableCell>
            <TableCell align="center" style={{width:'250px'}}>記録写真</TableCell>
            <TableCell align="center">備考</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            {cleanDataList && cleanDataList.data && cleanDataList.data.length > 0 && cleanDataList?.data.map((row, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell align="center"><p>{row?.date}</p><p>{row?.fin_time}</p></TableCell>
              <TableCell align="center">{row?.name}</TableCell>
              <TableCell align="center">{row?.tanto}</TableCell>
              <TableCell align="left">
                <div className="flex flex-wrap gap-2">
                {row.tmbs && row.tmbs.length > 0 && row.tmbs.map((tmb, tmbIndex) => (
                    <button key={tmbIndex} onClick={() => handleClickOpen(process.env.REACT_APP_ROOT_URL + "upload/" + row.pics[tmbIndex])}>
                        <img src={ process.env.REACT_APP_ROOT_URL + "upload/" + tmb + "?" + uniqueTimestamp} alt={`Thumbnail ${tmbIndex}`} style={{width:"40px"}} />
                    </button>
                ))}
                </div>
              </TableCell>
              <TableCell align="left">{row?.biko}</TableCell>
            </TableRow>
          ))} 
        </TableBody>
      </Table>
    </TableContainer>

    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
                <Pagination
                    count={totalPages}
                    page={page}
                    onChange={handleChangePage}
                    color="primary"
                />
            </Box>

            </div>
        </MntWrapper>
        <Dialog
                open={isDialogOpen}
                onClose={handleClose}
            >
                <DialogContent style={{ textAlign: 'center' }} className="flex">
                    {dialogSelectedImage && (
                        <div className="flex w-full">
                            <img src={dialogSelectedImage} alt="Selected" style={{ maxWidth: '100%', maxHeight: '80vh' }} />
                        </div>
                    )}
                </DialogContent>
            </Dialog>
        </>
    );
};
export default MntCleanList;