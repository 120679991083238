
type propsData = {
    Code : string,
}

const FloorMap = (
    props: propsData
  ) => {
    //console.log(props.Code);
    return (


<svg width="220" height="auto" version="1.1" viewBox="0 0 81.727 64.034" xmlns="http://www.w3.org/2000/svg"><g fill="none" stroke="#000"><g><rect x=".28908" y=".26339" width="35.387" height="26.243" strokeWidth=".5"/><rect x="35.701" y=".25662" width="29.489" height="12.532" strokeWidth=".5063"/><rect x="36.392" y="38.418" width="14.293" height="25.115" strokeWidth=".49897"/><rect x="65.18" y="12.827" width="16.103" height="50.699" strokeWidth=".50795"/></g><rect x="17.902" y=".62187" width=".16029" height="25.48" strokeLinejoin="round" strokeWidth=".44018"/></g><g fill="transparent">
<rect x="36.951" y="1.3964" width="27.058" height="10.194" fill={props.Code == "201" || props.Code == "301" ? "#6fdb9c" : ""}/>
<rect x="37.607" y="39.572" width="11.955" height="22.698" fill={props.Code == "202" || props.Code == "302" ? "#6fdb9c" : ""}/>
<rect x="1.3356" y="1.4325" width="15.549" height="23.906" fill={props.Code == "211" || props.Code == "311" ? "#6fdb9c" : ""}/>
 <rect x="19.01" y="1.4618" width="15.549" height="23.906" fill={props.Code == "212" || props.Code == "312" ? "#6fdb9c" : ""}/>

    <rect x="66.252" y="13.91" width="13.986" height="10.407" fill={props.Code == "221" || props.Code == "321" ? "#6fdb9c" : ""}/>
    <rect x="66.275" y="26.765" width="13.986" height="10.407" fill={props.Code == "222" || props.Code == "322" ? "#6fdb9c" : ""}/>
    <rect x="66.228" y="39.542" width="13.986" height="10.407" fill={props.Code == "223" || props.Code == "323" ? "#6fdb9c" : ""}/>
    <rect x="66.265" y="52.148" width="13.986" height="10.407" fill={props.Code == "224" || props.Code == "324" ? "#6fdb9c" : ""}/>
    </g><g><g transform="matrix(.01672 0 0 .01672 22.496 8.5591)" fill="#282828"><g fill="#282828">
	<path fill="#374149" d="m118.37 162.6h275.23c6.793 0 12.332-5.442 12.343-12.207 0-34.015-41.867-63.672-104.18-73.797-9.266-1.512-15.989-9.434-15.989-18.844v-57.754h-71.609v59.942c0 9.015-6.406 16.882-15.242 18.71-56.438 11.821-92.902 39.981-92.902 71.821 0.012 6.687 5.551 12.129 12.344 12.129z"/>
	<path fill="#374149" d="m81.888 305.27c-8.336-2.68-17.277 1.906-19.961 10.242-2.711 8.336 1.875 17.277 10.211 19.957 1.617 0.523 3.262 0.782 4.875 0.782 6.695 0 12.922-4.27 15.086-10.993 2.712-8.332-1.875-17.273-10.211-19.988z"/>
	<path fill="#374149" d="m108.49 222.94c-8.339-2.707-17.281 1.878-19.961 10.21-2.71 8.34 1.875 17.282 10.211 19.962 1.613 0.523 3.258 0.781 4.875 0.781 6.691 0 12.922-4.27 15.086-10.992 2.711-8.337-1.875-17.274-10.211-19.961z"/>
	<path fill="#374149" d="m28.701 469.97c-8.336-2.684-17.282 1.875-19.961 10.242-2.711 8.332 1.875 17.246 10.21 19.961 1.614 0.515 3.258 0.774 4.875 0.774 6.691 0 12.922-4.294 15.086-11.016 2.712-8.336-1.874-17.251-10.21-19.961z"/>
	<path fill="#374149" d="m55.295 387.62c-8.336-2.679-17.278 1.906-19.961 10.246-2.711 8.332 1.875 17.274 10.211 19.957 1.613 0.52 3.258 0.778 4.874 0.778 6.692 0 12.922-4.266 15.086-10.988 2.711-8.336-1.874-17.278-10.21-19.993z"/>
	<path fill="#374149" d="m206.9 222.25c-8.711-0.95-16.527 5.366-17.481 14.078-0.922 8.711 5.363 16.527 14.078 17.481 0.574 0.054 1.152 0.086 1.73 0.086 7.988 0 14.883-6.027 15.75-14.164 0.923-8.712-5.365-16.528-14.077-17.481z"/>
	<path fill="#374149" d="m188.38 394.34c-8.71-0.954-16.527 5.34-17.48 14.078-0.922 8.711 5.367 16.528 14.078 17.454 0.574 0.054 1.153 0.082 1.731 0.082 7.988 0 14.855-6.027 15.75-14.164 0.921-8.708-5.367-16.524-14.079-17.45z"/>
	<path fill="#374149" d="m197.65 308.29c-8.711-0.949-16.531 5.367-17.481 14.078-0.953 8.711 5.363 16.531 14.074 17.48 0.578 0.059 1.156 0.09 1.73 0.09 7.992 0 14.886-6.031 15.75-14.164 0.923-8.715-5.363-16.531-14.073-17.484z"/>
	<path fill="#374149" d="m179.13 480.36c-8.711-0.922-16.527 5.367-17.48 14.078-0.922 8.711 5.367 16.558 14.078 17.481 0.574 0.058 1.153 0.086 1.73 0.086 7.989 0 14.856-6.027 15.746-14.164 0.926-8.711-5.363-16.527-14.074-17.481z"/>
	<path fill="#374149" d="m332.9 480.36c-8.711 0.954-15.027 8.77-14.078 17.481 0.867 8.137 7.73 14.164 15.75 14.164 0.546 0 1.125-0.027 1.703-0.086 8.71-0.922 15.027-8.77 14.074-17.481-0.921-8.711-8.737-14.999-17.449-14.078z"/>
	<path fill="#374149" d="m327.01 425.87c8.71-0.926 15.031-8.742 14.078-17.454-0.922-8.738-8.77-15.031-17.453-14.078-8.738 0.926-15.027 8.742-14.074 17.449 0.863 8.137 7.73 14.164 15.75 14.164 0.546 1e-3 1.125-0.026 1.699-0.081z"/>
	<path fill="#374149" d="m308.5 253.81c8.711-0.954 15.027-8.77 14.074-17.481-0.922-8.711-8.77-15.027-17.481-14.078-8.679 0.953-15 8.769-14.074 17.48 0.895 8.137 7.758 14.164 15.778 14.164 0.547 0 1.125-0.031 1.703-0.085z"/>
	<path fill="#374149" d="m317.76 339.85c8.711-0.949 15.027-8.769 14.078-17.48-0.926-8.711-8.77-15.027-17.453-14.078-8.738 0.954-15.028 8.77-14.078 17.485 0.867 8.133 7.734 14.164 15.75 14.164 0.551 0 1.125-0.032 1.703-0.091z"/>
	<path fill="#374149" d="m476.66 397.86c-2.68-8.34-11.625-12.926-19.961-10.246-8.336 2.715-12.922 11.657-10.211 19.993 2.164 6.722 8.394 10.988 15.086 10.988 1.617 0 3.262-0.258 4.874-0.778 8.337-2.683 12.923-11.625 10.212-19.957z"/>
	<path fill="#374149" d="m503.26 480.22c-2.683-8.367-11.625-12.926-19.961-10.242-8.34 2.71-12.926 11.625-10.211 19.961 2.161 6.722 8.394 11.016 15.086 11.016 1.613 0 3.258-0.258 4.871-0.774 8.339-2.715 12.926-11.629 10.215-19.961z"/>
	<path fill="#374149" d="m423.5 233.15c-2.684-8.332-11.653-12.918-19.961-10.21-8.363 2.687-12.922 11.625-10.242 19.961 2.164 6.722 8.394 10.992 15.086 10.992 1.618 0 3.262-0.258 4.906-0.781 8.309-2.68 12.894-11.622 10.211-19.962z"/>
	<path fill="#374149" d="m450.1 315.51c-2.715-8.336-11.656-12.922-19.992-10.242-8.336 2.715-12.894 11.656-10.211 19.988 2.164 6.723 8.394 10.993 15.086 10.993 1.618 0 3.258-0.258 4.902-0.782 8.308-2.68 12.895-11.621 10.215-19.957z"/>
</g></g><g transform="matrix(.01672 0 0 .01672 4.8225 8.2811)" fill="#282828"><g fill="#282828">
	<path fill="#374149" d="m118.37 162.6h275.23c6.793 0 12.332-5.442 12.343-12.207 0-34.015-41.867-63.672-104.18-73.797-9.266-1.512-15.989-9.434-15.989-18.844v-57.754h-71.609v59.942c0 9.015-6.406 16.882-15.242 18.71-56.438 11.821-92.902 39.981-92.902 71.821 0.012 6.687 5.551 12.129 12.344 12.129z"/>
	<path fill="#374149" d="m81.888 305.27c-8.336-2.68-17.277 1.906-19.961 10.242-2.711 8.336 1.875 17.277 10.211 19.957 1.617 0.523 3.262 0.782 4.875 0.782 6.695 0 12.922-4.27 15.086-10.993 2.712-8.332-1.875-17.273-10.211-19.988z"/>
	<path fill="#374149" d="m108.49 222.94c-8.339-2.707-17.281 1.878-19.961 10.21-2.71 8.34 1.875 17.282 10.211 19.962 1.613 0.523 3.258 0.781 4.875 0.781 6.691 0 12.922-4.27 15.086-10.992 2.711-8.337-1.875-17.274-10.211-19.961z"/>
	<path fill="#374149" d="m28.701 469.97c-8.336-2.684-17.282 1.875-19.961 10.242-2.711 8.332 1.875 17.246 10.21 19.961 1.614 0.515 3.258 0.774 4.875 0.774 6.691 0 12.922-4.294 15.086-11.016 2.712-8.336-1.874-17.251-10.21-19.961z"/>
	<path fill="#374149" d="m55.295 387.62c-8.336-2.679-17.278 1.906-19.961 10.246-2.711 8.332 1.875 17.274 10.211 19.957 1.613 0.52 3.258 0.778 4.874 0.778 6.692 0 12.922-4.266 15.086-10.988 2.711-8.336-1.874-17.278-10.21-19.993z"/>
	<path fill="#374149" d="m206.9 222.25c-8.711-0.95-16.527 5.366-17.481 14.078-0.922 8.711 5.363 16.527 14.078 17.481 0.574 0.054 1.152 0.086 1.73 0.086 7.988 0 14.883-6.027 15.75-14.164 0.923-8.712-5.365-16.528-14.077-17.481z"/>
	<path fill="#374149" d="m188.38 394.34c-8.71-0.954-16.527 5.34-17.48 14.078-0.922 8.711 5.367 16.528 14.078 17.454 0.574 0.054 1.153 0.082 1.731 0.082 7.988 0 14.855-6.027 15.75-14.164 0.921-8.708-5.367-16.524-14.079-17.45z"/>
	<path fill="#374149" d="m197.65 308.29c-8.711-0.949-16.531 5.367-17.481 14.078-0.953 8.711 5.363 16.531 14.074 17.48 0.578 0.059 1.156 0.09 1.73 0.09 7.992 0 14.886-6.031 15.75-14.164 0.923-8.715-5.363-16.531-14.073-17.484z"/>
	<path fill="#374149" d="m179.13 480.36c-8.711-0.922-16.527 5.367-17.48 14.078-0.922 8.711 5.367 16.558 14.078 17.481 0.574 0.058 1.153 0.086 1.73 0.086 7.989 0 14.856-6.027 15.746-14.164 0.926-8.711-5.363-16.527-14.074-17.481z"/>
	<path fill="#374149" d="m332.9 480.36c-8.711 0.954-15.027 8.77-14.078 17.481 0.867 8.137 7.73 14.164 15.75 14.164 0.546 0 1.125-0.027 1.703-0.086 8.71-0.922 15.027-8.77 14.074-17.481-0.921-8.711-8.737-14.999-17.449-14.078z"/>
	<path fill="#374149" d="m327.01 425.87c8.71-0.926 15.031-8.742 14.078-17.454-0.922-8.738-8.77-15.031-17.453-14.078-8.738 0.926-15.027 8.742-14.074 17.449 0.863 8.137 7.73 14.164 15.75 14.164 0.546 1e-3 1.125-0.026 1.699-0.081z"/>
	<path fill="#374149" d="m308.5 253.81c8.711-0.954 15.027-8.77 14.074-17.481-0.922-8.711-8.77-15.027-17.481-14.078-8.679 0.953-15 8.769-14.074 17.48 0.895 8.137 7.758 14.164 15.778 14.164 0.547 0 1.125-0.031 1.703-0.085z"/>
	<path fill="#374149" d="m317.76 339.85c8.711-0.949 15.027-8.769 14.078-17.48-0.926-8.711-8.77-15.027-17.453-14.078-8.738 0.954-15.028 8.77-14.078 17.485 0.867 8.133 7.734 14.164 15.75 14.164 0.551 0 1.125-0.032 1.703-0.091z"/>
	<path fill="#374149" d="m476.66 397.86c-2.68-8.34-11.625-12.926-19.961-10.246-8.336 2.715-12.922 11.657-10.211 19.993 2.164 6.722 8.394 10.988 15.086 10.988 1.617 0 3.262-0.258 4.874-0.778 8.337-2.683 12.923-11.625 10.212-19.957z"/>
	<path fill="#374149" d="m503.26 480.22c-2.683-8.367-11.625-12.926-19.961-10.242-8.34 2.71-12.926 11.625-10.211 19.961 2.161 6.722 8.394 11.016 15.086 11.016 1.613 0 3.258-0.258 4.871-0.774 8.339-2.715 12.926-11.629 10.215-19.961z"/>
	<path fill="#374149" d="m423.5 233.15c-2.684-8.332-11.653-12.918-19.961-10.21-8.363 2.687-12.922 11.625-10.242 19.961 2.164 6.722 8.394 10.992 15.086 10.992 1.618 0 3.262-0.258 4.906-0.781 8.309-2.68 12.894-11.622 10.211-19.962z"/>
	<path fill="#374149" d="m450.1 315.51c-2.715-8.336-11.656-12.922-19.992-10.242-8.336 2.715-12.894 11.656-10.211 19.988 2.164 6.723 8.394 10.993 15.086 10.993 1.618 0 3.258-0.258 4.902-0.782 8.308-2.68 12.895-11.621 10.215-19.957z"/>
</g></g></g><g><rect x="65.161" y="50.788" width="16.056" height=".49732"/><rect x="65.021" y="38.01" width="16.474" height=".49732"/><rect x="64.932" y="25.264" width="16.625" height=".49732"/></g><g transform="matrix(.01318 0 0 .01318 69.596 15.209)"><g fill="#242424">
	<path fill="#374149" d="m450.15 16.926c-2.414-6.144-7.078-10.984-13.156-13.633l-2.809-1.214c-3.145-1.376-6.465-2.079-9.879-2.079-9.816 0-18.706 5.817-22.644 14.82l-59.004 135.12-23.883 54.531h0.07l-0.07 0.16h57.317l73.703-168.78c2.644-6.062 2.769-12.781 0.355-18.925z"/>
	<path fill="#374149" d="m444.31 292.07v-0.16h-384.19c0 74.762 83.453 135.37 143.16 135.37h26.489c17.504 0 31.969 13.008 34.258 29.882 0.621 4.566 1.098 9.153 1.89 13.692l7.191 41.153h144.12c14.961 0 27.086-12.125 27.086-27.086v-192.85z"/>
	<polygon fill="#374149" points="60.123 262.53 60.123 262.69 444.31 262.69 444.31 233.48 60.123 233.48 60.123 262.44"/>
</g></g><g transform="matrix(.01318 0 0 .01318 69.753 28.146)"><g fill="#242424">
	<path fill="#374149" d="m450.15 16.926c-2.414-6.144-7.078-10.984-13.156-13.633l-2.809-1.214c-3.145-1.376-6.465-2.079-9.879-2.079-9.816 0-18.706 5.817-22.644 14.82l-59.004 135.12-23.883 54.531h0.07l-0.07 0.16h57.317l73.703-168.78c2.644-6.062 2.769-12.781 0.355-18.925z"/>
	<path fill="#374149" d="m444.31 292.07v-0.16h-384.19c0 74.762 83.453 135.37 143.16 135.37h26.489c17.504 0 31.969 13.008 34.258 29.882 0.621 4.566 1.098 9.153 1.89 13.692l7.191 41.153h144.12c14.961 0 27.086-12.125 27.086-27.086v-192.85z"/>
	<polygon fill="#374149" points="60.123 262.44 60.123 262.53 60.123 262.69 444.31 262.69 444.31 233.48 60.123 233.48"/>
</g></g><g transform="matrix(.01318 0 0 .01318 69.561 41.194)"><g fill="#242424">
	<path fill="#374149" d="m450.15 16.926c-2.414-6.144-7.078-10.984-13.156-13.633l-2.809-1.214c-3.145-1.376-6.465-2.079-9.879-2.079-9.816 0-18.706 5.817-22.644 14.82l-59.004 135.12-23.883 54.531h0.07l-0.07 0.16h57.317l73.703-168.78c2.644-6.062 2.769-12.781 0.355-18.925z"/>
	<path fill="#374149" d="m444.31 292.07v-0.16h-384.19c0 74.762 83.453 135.37 143.16 135.37h26.489c17.504 0 31.969 13.008 34.258 29.882 0.621 4.566 1.098 9.153 1.89 13.692l7.191 41.153h144.12c14.961 0 27.086-12.125 27.086-27.086v-192.85z"/>
	<polygon fill="#374149" points="60.123 233.48 60.123 262.44 60.123 262.53 60.123 262.69 444.31 262.69 444.31 233.48"/>
</g></g><g transform="matrix(.01318 0 0 .01318 69.803 53.924)"><g fill="#242424">
	<path fill="#374149" d="m450.15 16.926c-2.414-6.144-7.078-10.984-13.156-13.633l-2.809-1.214c-3.145-1.376-6.465-2.079-9.879-2.079-9.816 0-18.706 5.817-22.644 14.82l-59.004 135.12-23.883 54.531h0.07l-0.07 0.16h57.317l73.703-168.78c2.644-6.062 2.769-12.781 0.355-18.925z"/>
	<path fill="#374149" d="m444.31 292.07v-0.16h-384.19c0 74.762 83.453 135.37 143.16 135.37h26.489c17.504 0 31.969 13.008 34.258 29.882 0.621 4.566 1.098 9.153 1.89 13.692l7.191 41.153h144.12c14.961 0 27.086-12.125 27.086-27.086v-192.85z"/>
	<polygon fill="#374149" points="60.123 262.44 60.123 262.53 60.123 262.69 444.31 262.69 444.31 233.48 60.123 233.48"/>
</g></g><g transform="matrix(.012438 0 0 .012438 47.036 3.569)"><g fill="#282828">
	<path fill="#374149" d="m505.58 356.64-8.172-7.785-12.296-11.675-67.446-64.133h-323.32l-67.446 64.133-12.301 11.676-8.172 7.785-6.421 6.109c0.121 1.168 0.234 2.336 0.43 3.465 8.523 59.778 59.973 105.74 122.12 105.74h266.9c62.152 0 113.6-45.961 122.12-105.74 0.195-1.129 0.313-2.297 0.43-3.465zm-356.6 55.301h-66.16c-18.25 0-33.078-14.828-33.078-33.078h99.238zm-84.29-73.085 1.746-1.676 38.762-36.89h301.61l38.762 36.89 1.133 1.086 0.602 0.707h-222.2z"/>
	<path fill="#374149" d="m303.91 100.13v15.41c0 3.992 3.234 7.226 7.222 7.226h16.523c3.989 0 7.223-3.234 7.223-7.226v-15.41z"/>
	<path fill="#374149" d="m190.94 259.35 3.691-35.45c0.656-7.335-5.125-13.652-12.488-13.652h-20.707c-7.367 0-13.145 6.317-12.488 13.652l3.691 35.45z"/>
	<path fill="#374149" d="m317.37 223.9 3.691 35.45h38.305l3.691-35.45c0.653-7.335-5.125-13.652-12.488-13.652h-20.707c-7.367 0-13.145 6.317-12.492 13.652z"/>
	<path fill="#374149" d="m277.41 194.98c0-5.328-4.286-9.641-9.594-9.714v-99.133c0-10.489 8.542-19.02 19.046-19.02 0.622 0 1.246 0.031 1.883 0.09 9.508 0.918 17.023 9.574 17.117 19.723v3.308h27.059l-0.031-3.39c-0.238-24.347-18.144-44.375-41.648-46.586-1.485-0.137-2.965-0.207-4.422-0.207-25.406 0-46.082 20.672-46.082 46.082v99.117h-1.274c-5.375 0-9.73 4.355-9.73 9.73v64.371h47.676z"/>
</g></g><g transform="matrix(.012438 0 0 .012438 40.39 46.537)"><g fill="#282828">
	<path fill="#374149" d="m505.58 356.64-8.172-7.785-12.296-11.675-67.446-64.133h-323.32l-67.446 64.133-12.301 11.676-8.172 7.785-6.421 6.109c0.121 1.168 0.234 2.336 0.43 3.465 8.523 59.778 59.973 105.74 122.12 105.74h266.9c62.152 0 113.6-45.961 122.12-105.74 0.195-1.129 0.313-2.297 0.43-3.465zm-356.6 55.301h-66.16c-18.25 0-33.078-14.828-33.078-33.078h99.238zm-84.29-73.085 1.746-1.676 38.762-36.89h301.61l38.762 36.89 1.133 1.086 0.602 0.707h-222.2z"/>
	<path fill="#374149" d="m303.91 100.13v15.41c0 3.992 3.234 7.226 7.222 7.226h16.523c3.989 0 7.223-3.234 7.223-7.226v-15.41z"/>
	<path fill="#374149" d="m190.94 259.35 3.691-35.45c0.656-7.335-5.125-13.652-12.488-13.652h-20.707c-7.367 0-13.145 6.317-12.488 13.652l3.691 35.45z"/>
	<path fill="#374149" d="m317.37 223.9 3.691 35.45h38.305l3.691-35.45c0.653-7.335-5.125-13.652-12.488-13.652h-20.707c-7.367 0-13.145 6.317-12.492 13.652z"/>
	<path fill="#374149" d="m277.41 194.98c0-5.328-4.286-9.641-9.594-9.714v-99.133c0-10.489 8.542-19.02 19.046-19.02 0.622 0 1.246 0.031 1.883 0.09 9.508 0.918 17.023 9.574 17.117 19.723v3.308h27.059l-0.031-3.39c-0.238-24.347-18.144-44.375-41.648-46.586-1.485-0.137-2.965-0.207-4.422-0.207-25.406 0-46.082 20.672-46.082 46.082v99.117h-1.274c-5.375 0-9.73 4.355-9.73 9.73v64.371h47.676z"/>
</g></g></svg>

  );
}
  export default FloorMap;