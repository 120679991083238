import React, { useState, useEffect, useContext } from "react";
// import axios from 'axios';
import api from '../../configs/axios';
import Wrapper from "./Wrapper";
import { useGetImageUrl } from "../../components/useGetImageUrl";
import { Link, useLocation  } from "react-router-dom";
import { IoCamera } from "react-icons/io5";
import FloorMap from "../../components/FloorMap";
import imageCompression from "browser-image-compression";
import { RiAlertFill } from "react-icons/ri";
import heic2any from "heic2any";
import {getFileNameWithoutExtention, getFileExtension} from "../../components/GnlFunc";
import { Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import StaffAuth from "./StaffAuth";

import {AppContext, useAppContext} from "../../components/AppContext";
import {Box,  Pagination, Dialog, DialogContent} from "@mui/material";

import { rotateImageToFile } from "../../components/ImageRotate";


interface State {
    catcode: string;
}

export type compressImageType = {
    maxSizeMB: number;
    useWebWorker: boolean;
    initialQuality: number;
  };

export const CleanArea = () =>{

    const { sysInfo, setSysInfo } = useAppContext();

    // const [text, setText] = useState('');

    const [isLoding, setIsLoding] = useState(true);
    const [loadingText, setLoadingText] = useState<string>("");
    const [inputValue, setInputValue] = useState<string>('');


    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dialogSelectedImage, setDialogSelectedImage] = useState<string | null>(null);

    // const handleInputChange = (value: string) => {
    //     setInputValue(value);
    // };

    
    //パラメータ取得
    const search = useLocation().search;
    const query = new URLSearchParams(search);
    const catcode = query.get('cat')

    //型定義
    type compressImageType = {
        maxSizeMB: number;
        useWebWorker: boolean;
        initialQuality: number;
    };
    type CleanAreaData = {
        code: string,
        name: string,
        catcode: string,
        catname: string,
        cleantime: string,
        cleantanto: string,
        cleanbiko: string,
        notionlink: string,
        extlink_name_1 : string,
        extlink_path_1 : string
    };
    type CleanAreaPicData = {
        code : string,
        seq_no : number,
        pic_path : string,
        pic_name : string,
        t_pic_path : string,
        direction : number
    }

    type UpdStatusType = {
        idx : number,
        status : string, // update or insert
        is_upd : number //1:〇 2:✕

    }

    type CleanAreaDataPrev = {
        clean_date : string,
        clean_tanto : string,
        clean_biko : string,
        pics : CleanAreaDataPrevPicsType[],
    }
    type CleanAreaDataPrevPicsType = {
        pic : string,
        tmb : string,
    }

    type UpdResType = {
        status : string,
        message : string,
    }

    const navigate = useNavigate();
    const [areaData, setAreaData] = useState<CleanAreaData | null>(null);
    const [areaPicData, setAreaPicData] = useState<CleanAreaPicData[]>([]);
    const [areaDataPrev, setAreaDataPrev] = useState<CleanAreaDataPrev | null>(null);
    const [uploadedFiles, setUploadedFiles] = useState<{ [key: number]: File | null }>({});
    const [previewUrls, setPreviewUrls] = useState<{ [key: number]: string }>({});
    const [inputBiko, setInputBiko] = useState<string>("");
    const [updStatus, setUpdStatus] = useState<UpdStatusType[]>([]);

    //今日の日付を取得
    const today = new Date();
    const today_formatted = today
      .toLocaleDateString("ja-JP", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      .split("/")
      .join("-");

    const uniqueTimestamp: string = Date.now().toString();

    //初回のみ実行
    useEffect(() => {

        const fetchData = async () => {

            const res = await api.get('data_api.php', {
                params: {
                    type: "get-cleanarea",
                    catcode : catcode,
                    todaydate : sysInfo?.clean_date,
                }
            });
            setAreaData(res.data);

            setInputBiko(res.data?.cleanbiko);

            const res2 = await api.get('data_api.php', {
                params: {
                    type: "get-cleanarea-pic",
                    code : res.data.code,
                    todaydate : sysInfo?.clean_date,
                }
            });

            setAreaPicData(res2.data);

            //console.table(res2.data);

            const res3 = await api.get('data_api.php', {
                params: {
                    type: "get-cleanarea-prev",
                    code : res.data.code,
                    todaydate : sysInfo?.clean_date,
                }
            });

            setAreaDataPrev(res3.data);
            // console.log("333"+res.data.code);
            //console.table(res3.data);

            const res2data : CleanAreaPicData[] = res2.data;

            //すでにアップロードされたファイルを退避
            const tmpPromises =  Array.from(res2data).map(async (data, index) => {

                if(data.t_pic_path !== ""){
                    const resData = await api.get("get_image.php",{
                        params: {
                            filename: data.t_pic_path
                        },
                        responseType : "blob"
                    }).then((r) => r) ;
                    
                      console.log(resData.data);

                      if (resData.data?.type !== "text/html"){

                        console.log("etst");

                        const imageFile : File = new File([resData.data], data.t_pic_path, {
                            type: resData.data.type,
                            lastModified: resData.data.lastModified,
                        });
                          //const imageFile : File = resData.data as File;
                        
                        // const imageFile : File = await fetch(process.env.REACT_APP_ROOT_URL+"upload/"+data?.t_pic_path,{
                        //     method: 'GET',
                        //     mode: 'cors'
                        //   }).then((r) => r) as File;
                        setUploadedFiles(prevFiles => ({
                            ...prevFiles,
                            [index]: imageFile,
                        }));
                        // setUploadedFiles({
                        //     ...uploadedFiles,
                        //     [index]: imageFile,
                        // });
                        // console.log(resData);
                        // console.log(imageFile)
    
                        // サムネイル表示用のURLを生成
                        const reader = new FileReader();
                        reader.onloadend = () => {
                            setPreviewUrls(prevUrls => ({
                                ...prevUrls,
                                [index]: reader.result as string,
                            }));
                            // setPreviewUrls({
                            // ...previewUrls,
                            // [index]: reader.result as string,
                            // });
                        };
                      reader.readAsDataURL(imageFile);

                      }

                }
            });
            await Promise.all(tmpPromises);

            //console.log("aaa");
            // console.log(uploadedFiles);
//console.log(previewUrls);
            setIsLoding(false);

        }

        fetchData();
        
    }, []);

    

    //画像アップロード時、サムネイル表示
    const handleImageFileInputChange = async(id:number, e: React.ChangeEvent<HTMLInputElement>, pic_no:number)  => {

        

        const picData : CleanAreaPicData = areaPicData.find((n : CleanAreaPicData) => n.seq_no = pic_no) as CleanAreaPicData;
        console.table(picData);

        setIsLoding(true);
        setLoadingText("画像読込中");

        if (e.currentTarget?.files && e.currentTarget.files[0]) {

            let imageFile : File = e.currentTarget.files[0];

             //console.log(imageFile);

            const imageFileExt = getFileExtension(imageFile.name)?.toLowerCase();

           

            //iphoneの画像形式をjpgに変換
            if (imageFile.type === 'image/heif' || imageFileExt === "heif" || imageFile.type === 'image/heic' || imageFileExt === "heic") {
                const outputBlob = await heic2any({
                    blob: imageFile,
                    toType: 'image/jpeg',
                });
                //console.log(outputBlob);
                const imageFileNameNew : string = getFileNameWithoutExtention(imageFile.name) + ".jpg"; 
                imageFile = new File([outputBlob] as Blob[], getFileNameWithoutExtention(imageFile.name) + ".jpg", { type: 'image/jpeg' });
                // imageFile = new File(outputBlob as Blob[], imageFile.name, { type: 'image/jpeg' });
                // console.log("sfadf");
                // console.log(outputBlob);
            }

            //画像圧縮設定
            const icOption: compressImageType = {
                maxSizeMB: 1,
                useWebWorker: true,
                initialQuality: 0.85,
            }

            try{

                //画像圧縮処理
                // const compressedFile : File = new File([imageFile], imageFile.name, {
                //     type: imageFile.type,
                //     lastModified: imageFile.lastModified,
                // });
                const compressedFile = await imageCompression( imageFile as File , icOption);
                
                //画像回転
                let processedFile : File;
                // if (picData.direction == 2){
                //     processedFile = await rotateImageToFile(compressedFile);
                // }else{
                     processedFile = compressedFile;
                // }
              
                //画像ファイル退避
                setUploadedFiles({
                    ...uploadedFiles,
                    [id]: processedFile,
                });

                // サムネイル表示用のURLを生成
                const reader = new FileReader();
                reader.onloadend = () => {
                    setPreviewUrls({
                    ...previewUrls,
                    [id]: reader.result as string,
                    });
                };
                reader.readAsDataURL(processedFile);


                // //画像ファイル退避
                // setUploadedFiles({
                //     ...uploadedFiles,
                //     [id]: compressedFile,
                // });

                // // サムネイル表示用のURLを生成
                // const reader = new FileReader();
                // reader.onloadend = () => {
                //     setPreviewUrls({
                //     ...previewUrls,
                //     [id]: reader.result as string,
                //     });
                // };
                // reader.readAsDataURL(compressedFile);

            }catch(e){
                console.log(e);

                alert("選択された画像はアップロードできません！");

            }

            

            // console.log(compressedFile);

        }

        setIsLoding(false);
        setLoadingText("");

    }

    const [errMsg, setErrMsg] = useState<string>("");

  

    //フォーム送信
    const handleSubmit = async () => {

        setIsLoding(true);

       //入力バリデーション
       let strErrMsg = "";
       let cntImageUploaded = 0;

        //画像
        areaPicData.map((data, index) => {
            if(uploadedFiles[index]){
                cntImageUploaded++;
            }
        });

        if ( !(areaPicData.length === cntImageUploaded) ){
            strErrMsg = "・清掃写真をすべて撮影してください。";
            setErrMsg(strErrMsg);
            return;
        }
        
        const formData = new FormData();

        // //画像圧縮設定
        // const icOption: compressImageType = {
        //     maxSizeMB: 20,
        //     useWebWorker: true,
        //     initialQuality: 0.85,
        // }

        // const compressedFilesPromises = Array.from(areaPicData).map(async (data, index) => {

        //     const imageFile = uploadedFiles[index] as File;

        //     //iphoneの画像形式をjpgに変換
        //     if (imageFile.type === 'image/heif' || imageFile.type === 'image/heic') {
        //         const outputBlob = await heic2any({
        //           blob: imageFile,
        //           toType: 'image/jpeg',
        //         });
        //         const imageFileNameNew : string = getFileNameWithoutExtention(imageFile.name) + ".jpg"; 
        //         uploadedFiles[index] = new File(outputBlob as Blob[], imageFile.name, { type: 'image/jpeg' });
        //       }

        //     const compressedFile = await imageCompression( uploadedFiles[index] as File , icOption);
        //     formData.append("images[]", compressedFile, compressedFile.name);

        // });

        //  // 全ての圧縮処理が完了するのを待つ
        // await Promise.all(compressedFilesPromises);

        Object.entries(uploadedFiles).forEach(([key, file]) => {
            formData.append("images[]", file as File, file?.name);
        });

        // ローカルストレージからキーを指定して取得
        const staffname : string = localStorage.getItem("staffname") as string;
        formData.append("tanto", staffname);
        formData.append("biko", inputBiko);
        formData.append("type", "upd-cleanarea");
        formData.append("mccode", areaData?.code as string);
        formData.append("todaydate", sysInfo?.clean_date as string);

        // console.log("fafd");
        // console.table(uploadedFiles);

        try {
            const res = await api.post('data_api.php?type=upd-cleanarea', formData, {
                // headers: {
                //     'Content-Type': 'multipart/form-data'
                // },
            });

            if (res.data?.status as string === "success"){

                //一覧ページに遷移
               //console.log("aaa");
                navigate('/staff/cleanlist');
                return;

            }
            //console.table(res.data);

        }
        catch(error){
            console.log(error); 
        }

        setIsLoding(false);

    }

   // console.table(areaDataPrev);

    // if(isLoding){
    //     return <p>Loading...</p>
    // }

    const handleClickOpen = (imageSrc: string) => {
        setDialogSelectedImage(imageSrc);
        setIsDialogOpen(true);
    };

    const handleClose = () => {
        setIsDialogOpen(false);
        setDialogSelectedImage(null);
    };

    return (
        <>
        <StaffAuth>
        <Wrapper isLoading={isLoding} loadingText={loadingText}>
        <div className="block text-left pt-3">

                <ul className="b-guide">
                    <li><Link to="../staff/cleanlist">清掃リスト</Link></li>
                    <li>{areaData?.catname}</li>
                </ul>
                <p className="text-right text-12px">{sysInfo?.clean_date !== "" ? sysInfo?.clean_date : today_formatted}</p>
                <h1 className="text-20px pb-2 border-b border-gray-800 mb-4">{areaData?.catname}</h1>
                <div className="b-picareaguide mb-3">
                    <p className="picareaguidetitle mb-3">本日の記録箇所</p>
                    <p className="text-20px text-green-800 text-center">{areaData?.name}</p>
                    <div className="flex items-center justify-center p-3">
                        
                        <FloorMap Code={areaData?.code as string} />
                    </div>
                    
                </div>
                <div className="mb-4 flex flex-col gap-2">
                    {areaPicData !== null && areaPicData.map((data, index) => (
                        <div key={index}>
                            <p className="text-14px mb-2">{data.pic_name}</p>
                            <p><img src={`../../images/hostel/${data.pic_path}?${uniqueTimestamp}`} className='w-full' /></p>
                        </div>
                    ))}                    
                </div>

                <div className="flex flex-col gap-y-3 mb-5">
                    <a target="_blank" href={areaData !== null ? areaData?.notionlink.toString() as string : ""} className="btn-1 btn-style-blue-1">
                        <span className="block text-14px">清掃箇所について</span>
                        <span className="block text-12px">（外部リンク）</span>
                    </a>

                    {areaData !== null && areaData.extlink_name_1 !== '' ? (
                        <>
                        <a target="_blank" href={areaData?.extlink_path_1.toString() as string} className="btn-1 btn-style-blue-1">
                            <span className="block text-14px">{areaData?.extlink_name_1.toString() as string}</span>
                            <span className="block text-12px">（外部リンク）</span>
                        </a>
                        </>
                    ) : ""}
                </div>
                
                {areaDataPrev !== null && areaDataPrev?.clean_date != "" ? (
                    <>
                        <h2 className="text-sm mb-2">前回記録写真</h2>
                        <div className="p-3 border border-solid border-gray-400 rounded-sm bg-gray-200 mb-4 b-prevcleanpic">
                            <div className=" mb-2">
                                <p className="text-12px leading-none leading-normal">{areaDataPrev?.clean_date} ／ {areaDataPrev?.clean_tanto}</p>
                                {areaDataPrev.clean_biko != "" ? (
                                    <>
                                    <p className="text-12px leading-none leading-normal">{areaDataPrev.clean_biko}</p>
                                    </>
                                ) : ""}
                            </div>
                            <div className="mb-2 flex gap-2 flex-wrap">
                                {areaDataPrev?.pics.map((pic_path,pic_idx) => (
                                    <button onClick={()=>handleClickOpen(process.env.REACT_APP_ROOT_URL+'upload/'+pic_path.pic)}>
                                        <img key={pic_idx} src={`${process.env.REACT_APP_ROOT_URL+'upload/'+pic_path.tmb+"?"+uniqueTimestamp}`} className='w-14' />
                                    </button>
                                ))}
                            </div>
                            <p className="text-10px leading-none text-gray-600">※画像クリックで拡大</p>
                        </div>
                    </>
                ) : ''}
                

                <h2 className="text-sm mb-1">清掃記録写真</h2>
                <p className="text-12px text-red-700 mb-2">※参考写真と同じ構図になるように撮影してください。</p>

                {areaPicData.map((data, index) => {
                    // const { imageUrl } = useGetImageUrl({ file: data.data_file });
                    return (
                        <div key={index}>
                            <p className="text-10px mb-1">{data.pic_name}</p>
                            {previewUrls[index] && (
                            <div>
                                <p className="mb-2">                                    
                                    <img
                                    src={previewUrls[index]}
                                    alt="Uploaded Preview"
                                    style={{ width: '100%', height: 'auto', objectFit: 'cover' }}
                                    className="border border-gray-400 border-solid"
                                    />
                                </p>
                            </div>
                            )}
                            <label className={`mb-4 ${previewUrls[index] ? 'b-camera-re' : 'b-camera'}`} >
                                {previewUrls[index] ? (
                                    <>
                                    <p className="text-14px">再撮影する</p>
                                    </>
                                    
                                ) : (
                                    <>
                                    <img src={`../../images/hostel/${data.pic_path}`} style={{opacity:0.2}} />
                                    <div className="flex flex-col items-center justify-center absolute b-camera-button">
                                    <IoCamera className="icn-camera mb-1" size={30} color={'#fff'} />
                                    <p className="text-white text-13px">写真を撮影</p>
                                    {/* <p className="text-gray-900 text-12px">({data.direction == 2 ? "横向き" : "縦向き"})</p> */}
                                    </div>                                   
                                    </>
                                )}
                                
                                <input type="file" capture="environment" accept="image/*" onChange={e => {
                                    handleImageFileInputChange(index, e, data.seq_no)
                                } } hidden />
                            </label>                            
                        </div>
                    );
                })}
                
                <p className="text-xs mb-2">特記事項</p>
                <textarea className="w-full h-20 border border-solid border-gray-500 mb-5" value={inputBiko} onChange={(e)=>{setInputBiko(e.target.value)}} />

                {
                    errMsg != "" && (
                        <>
                            <div className="rounded border border-red-300 bg-red-100 p-4 mb-7">
                                <p className="text-red-800 flex items-center gap-1 text-13px mb-2"><RiAlertFill size={18} />入力エラー　</p>
                                <p className="text-red-800">{errMsg}</p>
                            </div>
                        </>
                    )
                }

                <button className="btn-1 btn-style-blue-1" onClick={() => {handleSubmit()}}>
                    <span className="block text-14px">清掃完了</span>
                </button> 


            </div>
        </Wrapper>
        <Dialog
            open={isDialogOpen}
            onClose={handleClose}
            style={{padding:0}}
        >
            <DialogContent style={{ textAlign: 'center',padding:0 }} className="flex" >
                {dialogSelectedImage && (
                    <>
                    <img src={dialogSelectedImage} alt="Selected" style={{ maxWidth: '100%', maxHeight: '80vh' }} />
                    {/* <div className="flex w-full">
                        <img src={dialogSelectedImage} alt="Selected" style={{ maxWidth: '100%', maxHeight: '80vh' }} />
                    </div> */}
                    </>
                    
                )}
            </DialogContent>
        </Dialog>
        </StaffAuth>
        </>  
    )
}
export default CleanArea;