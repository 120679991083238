export const getFileNameWithoutExtention = function(fileNameStr : string) : string {
    // 文字列中の最後の `.` の位置を取得
    const extensionIndex = fileNameStr.lastIndexOf('.');
    const hasExtension = extensionIndex > 0 && extensionIndex < fileNameStr.length - 1;
    // '.' 以前がファイル名
    const fileName =  hasExtension ? fileNameStr.substring(0, extensionIndex) : fileNameStr;
    return fileName;
}

export const getFileExtension = function(filename: string): string | undefined {
    const parts = filename.split('.');
    return parts.length > 1 ? parts.pop() : "";
}