import React, { useState, useEffect,FC, ReactNode } from "react";
import { Box, List } from "@mui/material";
import Drawer from '@mui/material/Drawer';
import { Link } from "react-router-dom";
import '../../css/MntShared.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {makeStyles, Theme} from "@mui/material/styles";
import { IoClose } from "react-icons/io5";
import { FaUserCircle } from "react-icons/fa";
import { RiLogoutCircleRLine } from "react-icons/ri";
import { AppContext, useAppContext } from "../../components/AppContext";
import CircularProgress from '@mui/material/CircularProgress';

interface LayoutProps {
    children: ReactNode;
    isLoading : boolean;
    loadingText : string;
}


export const MntWrapper: FC<LayoutProps> = ({children, isLoading, loadingText}) =>{

    const {MntLogout} = useAppContext();

    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(true);

    useEffect(() => {

       

    }, []);

    const drawerWidth = 195;

    const handleDrawerMenu = () => {
        setIsDrawerOpen(!isDrawerOpen);
    }

    const handleLogoutOnClick = () => {
        MntLogout();
    }

    return (
        <>
        <div className="flex b-mnt-wrap">
            {isLoading ? (
                <div className="b-loadingcover">
                    <p><CircularProgress/></p>
                    <p className="text-blue-700 text-14px">{loadingText}</p>
                </div>
            ) : ''}   
            <Drawer variant="permanent" open={isDrawerOpen}
              sx={{
                width: isDrawerOpen ? drawerWidth : 0,
                flexShrink: isDrawerOpen ? 0 : 'auto',
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                    backgroundColor: '#31454e',
                    color: '#fff',
                    transition: 'transform 0.2s ease-in',
                    transform: isDrawerOpen ? 'translateX(0)' : `translateX(-${drawerWidth}px)`,
                },
                transition : 'width 0.2s ease-in',
            }}
            >
               
                <div className="b-mnt-drawer">
                    <div className="flex b-mnt-systitle">
                    <img src="../../images/almondlogo.png" className='w-8' />
                    <div className="flex flex-col gap-1 items-start">
                        <p>清掃管理システム</p>
                        <p className="text-10px">ver.1.0.0</p>
                    </div>
                    </div>
                <ul className="flex flex-col items-start b-mnt-menu-list">
                    <li className="b-mnt-menu-item"><Link to="../mnt/cleanlatest" className="b-mnt-menu-link">最新の清掃状態</Link></li>
                    <li className="b-mnt-menu-item"><Link to="../mnt/cleanlist" className="b-mnt-menu-link">清掃履歴一覧</Link></li>                    
                </ul>
                </div>
                
            </Drawer>
         
            <div className="w-full">
                <header className="b-mnt-header">
                    <button className="mnt-mainmenu-icn" onClick={()=>handleDrawerMenu()}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                    <button className="flex items-center gap-2" onClick={() => {handleLogoutOnClick()}}>
                        <RiLogoutCircleRLine size={18} />
                        <p className="text-11px">ログアウト</p>
                    </button>
                </header>
                <main className="w-full text-left b-mnt-main-wrap">
                    {children}
                </main>
            </div>
            
        </div>
        </>
    );
};
export default MntWrapper;