import React, { useState, useEffect } from "react";
import MntWrapper from "./MntWrapper";
import api from '../../configs/axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TextField } from "@mui/material";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {Box,  Pagination, Dialog, DialogContent} from "@mui/material";
import { TbStairs } from "react-icons/tb";

type CleanPicsLatestType = {
    floor : string;
    clean_code : string;
    clean_date : string;
    place_name : string;
    rec_no : number;
    tmb : string;
    pic : string;
}

export const MntCleanLatest = () =>{

    const [isloading, setLoading] = useState<boolean>(false);
    const [loadingText, setLoadingText] = useState<string>("");
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dialogSelectedImage, setDialogSelectedImage] = useState<string | null>(null);

    const [cleanPicsLatest, setCleanPicsLatest] = useState<CleanPicsLatestType[] | null>(null);

    const uniqueTimestamp: string = Date.now().toString();

    useEffect(() => {

        setLoadingText("");
        setLoading(true);


        


        const fetchData = async () => {

            const res = await api.get('data_api.php', {
                params: {
                    type: "get-mnt-cleanpics-latest",
                }
            });
            setCleanPicsLatest(res.data as CleanPicsLatestType[]);      
            console.table(res.data);

        }

        fetchData();

        setLoadingText("");
        setLoading(false);
        
    }, []);

    const handleClickOpen = (imageSrc: string) => {
        setDialogSelectedImage(imageSrc);
        setIsDialogOpen(true);
    };

    const handleClose = () => {
        setIsDialogOpen(false);
        setDialogSelectedImage(null);
    };

    return (
        <>
        <MntWrapper isLoading={isloading} loadingText={loadingText}>
            <div>
                <h1 className="mnt-pagetitle">最新の清掃状態</h1>
            
                <div className="">
                    {
                        ["2","3"].map((idx) => (
                            <>
                            <div className="mb-3 b-mnt-pics-latest-floor text-24px" ><TbStairs size={22}  />{idx}F</div>
                            <div className="mb-8 b-mnt-pics-latest-wrap">
                            {cleanPicsLatest !== null && cleanPicsLatest?.length > 0 && cleanPicsLatest.map((data, index) => (
                                data.floor == idx && (
                                    <>
                                    
                                        <div className="b-mnt-pics-latest-item" >
                                            <div className="mnt-pics-latest-title" >
                                                <p className="text-12px font-medium">{data.place_name} _ {data.rec_no}</p>
                                                <p className="text-12px font-thin">{data.clean_date}</p>
                                            </div>
                                            <button className="mnt-pics-latest-picbutton" onClick={() => handleClickOpen(process.env.REACT_APP_ROOT_URL + "upload/" + data.pic)}>
                                                <img className="mnt-pics-latest-pic" src={process.env.REACT_APP_ROOT_URL + "upload/" + data.tmb + "?" + uniqueTimestamp} />
                                            </button>
                                        </div>
                                        
                                    </>
                                )
                            ))}
                            </div>
                            </>          
                        ))
                    }

                    {/* <div className="mb-3 b-mnt-pics-latest-floor">2F</div>
                    <div className="b-mnt-pics-latest-wrap">
                    {cleanPicsLatest !== null && cleanPicsLatest?.length > 0 && cleanPicsLatest.map((data, index) => (
                        data.floor == "2" && (
                            <>
                                <div className="b-mnt-pics-latest-item">
                                    <p className="mnt-pics-latest-title">{data.place_name}</p>
                                    <img className="mnt-pics-latest-pic" src={process.env.REACT_APP_ROOT_URL + "upload/" + data.tmb} />
                                </div>
                                
                            </>
                        )
                    ))}
                    </div> */}
                

                </div>

            </div>
        </MntWrapper>
        <Dialog
            open={isDialogOpen}
            onClose={handleClose}
        >
            <DialogContent style={{ textAlign: 'center' }} className="flex">
                {dialogSelectedImage && (
                    <div className="flex w-full">
                        <img src={dialogSelectedImage} alt="Selected" style={{ maxWidth: '100%', maxHeight: '80vh' }} />
                    </div>
                )}
            </DialogContent>
        </Dialog>
        </>  
    );
};
export default MntCleanLatest;