import React, { useState, useEffect, useContext  } from "react";
import api from '../../configs/axios';
import Wrapper from "./Wrapper";
import { Link } from "react-router-dom";
import { IoCamera } from "react-icons/io5";
import { MdArrowRight } from "react-icons/md";
import StaffAuth from "./StaffAuth";

import {AppContext, useAppContext} from "../../components/AppContext";


interface PageContentProps {
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CleanList = () =>{

    const { sysInfo, setSysInfo } = useAppContext();

    

    const [text, setText] = useState('');
    const [isloading, setLoading] = useState<boolean>(false);
    const [loadingText, setLoadingText] = useState<string>("");
    const [inputValue, setInputValue] = useState<string>('');

    const [today, setToday] = useState<string>('');
    const [todayInput, setTodayInput] = useState<string>('');

    const handleInputChange = (value: string) => {
        setInputValue(value);
    };

    type CleanListData = {
        code: string,
        name: string,
        status: string,
        tanto_name: string,
        fin_time: string
    };

    const [datas, setData] = useState<CleanListData[]>([]);

    useEffect(() => {

         //今日の日付を取得
         let sd : string = sysInfo?.clean_date as string;
         let d;
         if(sd === undefined || sd == ""){
             d = new Date();
         }
         else
         {
             d = new Date(sd.replace(/-/g,"/"));
         }
         console.log(d);
         let year = d.getFullYear();
         let month = d.getMonth() + 1;
         let day = d.getDate();
         let dayofweek = d.getDay();
         const dayname = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
         setToday(year + '.' + month + '.' + day + ' ' + '[' + dayname[dayofweek] + ']');

        api.get('data_api.php', {
            params: {
              type: "get-cleanlist",
              todaydate: year + '-' + month + '-' + day,
            }
          })
        .then(res => {
            setData(res.data)
            console.log(res.data);
        });

       

        // setSysInfo({...sysInfo, clean_date:"aaa"})

        setLoading(false);
    }, [today]);

    

    const handleInputTestDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log("test");
        setSysInfo({...sysInfo, clean_date:e.target.value});

        //今日の日付を取得
        let d = new Date(e.target.value.replace(/-/g,"/"));
        console.log(d);
        let year = d.getFullYear();
        let month = d.getMonth() + 1;
        let day = d.getDate();
        let dayofweek = d.getDay();
        const dayname = ['Sun','Mon','Tue','Wed','Thu','Fri','Sat'];
        setToday(year + '.' + month + '.' + day + ' ' + '[' + dayname[dayofweek] + ']');
        
    // api.get('data_api.php', {
    //     params: {
    //       type: "get-cleanlist",
    //       todaydate: year + '-' + month + '-' + day,
    //     }
    //   })
    // .then(res => {
    //     setData(res.data)
    //     console.log(res.data);
    // });

    };

//console.log(localStorage.getItem("staffname"));
    return (
        <>
        <StaffAuth>
            <Wrapper isLoading={isloading} loadingText={loadingText}>
                <div className="block text-left pt-5">
                    <p className="text-10px text-gray-600 leading-none mb-2">清掃リスト</p>
                    <h1 className="text-xl mb-4 tracking-wider border-b border-solid border-gray-500 pb-2">{today}</h1>
                    <ul className="b-cleanlist">
                    {datas.map((data) => (
                        <li key={data.code} className={`b-cleanlist-item ${data.status=="ok"?'done':''}`}>
                            <Link to={`../staff/cleanarea?cat=${data.code}`} className="b-cleanlist-item-link">
                                <div className="flex flex-col items-start gap-1">
                                    <p className="text-15px">{data.name}</p>
                                    <p className="text-10px">
                                    {data.status == "ok" ? (
                                    <>
                                        完了／{data.tanto_name+" "+data.fin_time}
                                    </>
                                    ):(
                                    <>
                                        未完了
                                    </>
                                    )}</p>
                                </div>
                            </Link>
                        </li>
                    ))}
                        
                    </ul>
                    {/* <div className="flex mt-10 gap-2">
                    <p className="">テスト用日付変更</p>
                    <input type="date" onChange={handleInputTestDateChange} />
                    <p>{today}</p>
                    </div> */}
                    
                </div>
            </Wrapper>     
        </StaffAuth>
        
        </>  
    )
}
export default CleanList;